import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import ApproachDetails from "./components/ApproachDetails";
import ResultsDetails from "./components/ResultsDetails";
import ConclusionDetails from "./components/ConclusionDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { config } from "react-spring";

import Carousel from "react-spring-3d-carousel";

const Ola = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/ola/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/ola/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/ola/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/ola/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/ola/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/ola/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform: goToSlide === index ? "scale(1.5)" : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Electronique" />
            </div>

            <HeaderText>
              L'Automatisation au Service du Contrôle de Gestion
            </HeaderText>
          </div>
        </div>
        <div className="grid md:grid-cols-12  mt-12">
          <div className="md:col-span-4"></div>
          <div className="md:col-span-8">
            <p className="font-bold mb-6">Contexte</p>
            <p>
              Les contrôleurs de gestion, piliers du suivi financier dans les
              grandes entreprises, sont souvent confrontés à un obstacle majeur
              : la collecte en temps réel des informations financières. Au
              Cameroun, où les grandes entreprises gèrent des transactions via
              multiples comptes bancaires, la tâche de surveiller et de
              rapporter les activités financières se transforme en un défi
              colossal, exacerbé par la dispersion des données et la nécessité
              d'une conformité à 100%.
            </p>
          </div>
        </div>
      </SectionContainer>

      <SectionContainer>
        <img
          src="/assets/images/ola.svg"
          alt="antene"
          className="max-w-full w-full h-auto"
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="L'Innovation par l'Automatisation"
          secondChildren={
            <p>
              Pour relever ce défi, nous avons introduit une solution
              d'agrégation de comptes bancaires révolutionnaire, propulsée par
              le Robotic Process Automation (RPA). Notre solution sert de point
              central pour toutes les transactions financières de l'entreprise,
              offrant une interface simplifiée qui rationalise la gestion des
              budgets et la conformité des transactions.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Solution" secondChildren={<ApproachDetails />} />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4 md:hidden">
          <img
            src="/assets/images/ola/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/ola/img2.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/ola/img3.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Résultats" secondChildren={<ResultsDetails />} />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Innovation au Cœur de la Solution"
          secondChildren={
            <p>
              L'utilisation du RPA pour surmonter l'absence d'interfaces de
              programmation bancaires représente une avancée significative. En
              automatisant la collecte de données, nous avons non seulement
              simplifié le processus de gestion financière mais aussi renforcé
              la sécurité en utilisant des profils comptables à droits de
              lecture uniquement, hébergés sur un cloud sécurisé.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={<ConclusionDetails />}
        />
      </SectionContainer>
      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(Ola);
