import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Face à cette crise, notre société, basée au Cameroun, a pris les rênes
        avec agilité. Forts d'une expertise en support applicatif sur de vastes
        infrastructures, nous avons initié un virage stratégique, implémentant
        des processus clairs et formant les équipes à cette nouvelle dynamique.
        Notre intervention visait non seulement à restaurer la stabilité mais
        aussi à propulser la plateforme vers de nouveaux sommets d'innovation et
        d'excellence opérationnelle.
      </p>
      <h3 className="leading-[40px] text-[32px]   ">
        Stratégie de Transformation
      </h3>
      <div>
        <h5 className="font-bold">Ouverture et Accessibilité</h5>
        <p>
          Nous avons élargi les fonctionnalités de la plateforme pour permettre
          aux entreprises de s'enregistrer, réaliser des déménagements, et
          rediriger leur courrier, étendant des services jusqu'alors réservés
          aux particuliers.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Sécurité et Conformité</h5>
        <p>
          La passerelle de paiement a été revue et corrigée pour renforcer sa
          robustesse et son niveau de conformité avec les standards PCI DSS,
          assurant une sécurité maximale pour les transactions des utilisateurs.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Optimisation Cloud</h5>
        <p>
          La refonte de l'architecture des services sur AWS a permis une
          utilisation plus rationnelle des ressources cloud. Grâce à l'adoption
          des meilleures pratiques AWS, une approche infrastructure as code, une
          documentation exhaustive, et une sécurité renforcée, nous avons
          réalisé une infrastructure plus performante et économique.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
