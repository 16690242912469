import React from "react";

const ResultsDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <div>
        <h5 className="font-bold">Résultats Transformateurs</h5>
        <p>
          Notre partenariat avec le géant postal a débloqué de nouvelles avenues
          de croissance et d'innovation. En mettant l'accent sur la sécurité,
          l'expérience utilisateur, et l'amélioration continue, nous avons non
          seulement surmonté les défis immédiats mais avons également posé les
          bases d'un avenir digital plus prometteur pour notre client.
          Aujourd'hui, nous sommes considérés comme un partenaire stratégique,
          collaborant étroitement avec divers départements pour piloter et
          implémenter des améliorations significatives.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Innovation au Cœur de la Stratégie</h5>
        <p>
          Notre approche a révolutionné la façon dont les services postaux sont
          livrés en Océanie, grâce à une plateforme numérique hautement
          adaptable, sécurisée, et orientée utilisateur. En embrassant
          l'innovation et en anticipant les besoins futurs, nous avons non
          seulement relevé les défis présents mais aussi ouvert la voie à des
          opportunités inédites dans le secteur postal.
        </p>
      </div>
    </div>
  );
};

export default ResultsDetails;
