export const carouselDate: {
  image: string;
  title: string;
  badge: string;
  link: string;
  linkCase: string;
  description: string;
  imageUrl: string;
}[] = [
  {
    badge: "Paie",
    image: "/assets/images/carousel1.svg",
    imageUrl: "/assets/images/case-logistic.svg",
    title: "L'Automatisation au Service du Contrôle de Gestion",
    link: "case-studies/payrollr",
    linkCase: "payrollr",
    description:
      "Cutting the deployment time by 3 and saving millions of euros",
  },
  {
    badge: "Telecommunications",
    image: "/assets/images/carousel2.svg",
    imageUrl: "/assets/images/case-logistic.svg",
    title:
      "Plateforme Digitale : Une Réponse Agile aux Défis de la Télécommunication",
    link: "case-studies/telecomunication",
    linkCase: "telecomunication",
    description:
      "Cutting the deployment time by 3 and saving millions of euros",
  },

  {
    badge: "Logistique",
    image: "/assets/images/carousel2.png",
    title:
      "Plateforme Digitale : Une Réponse Agile aux Défis de la Télécommunication",
    link: "/case-studies/logistic",
    linkCase: "logistic",
    description:
      "Cutting the deployment time by 3 and saving millions of euros",
    imageUrl: "/assets/images/case-logistic.svg",
  },
  {
    badge: "Electronique",
    image: "/assets/images/carousel4.svg",
    title:
      "Révolution dans la Gestion des Retours : Un Système de RMA Innovant pour un Géant des Télécoms",
    link: "/case-studies/electronic-rma",
    linkCase: "electronic-rma",
    description:
      "Cutting the deployment time by 3 and saving millions of euros",
    imageUrl: "/assets/images/case-logistic.svg",
  },
  {
    badge: "Electronique",
    image: "/assets/images/carousel5.svg",
    title:
      "Optimisation de la Logistique Avale : Un Système Sur Mesure pour un Leader des Terminaux de Paiement",
    link: "/case-studies/electronic-ola",
    linkCase: "electronic-ola",
    description:
      "Cutting the deployment time by 3 and saving millions of euros",
    imageUrl: "/assets/images/case-logistic.svg",
  },
];
