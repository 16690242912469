import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import ApproachDetails from "./components/ApproachDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { config } from "react-spring";

import Carousel from "react-spring-3d-carousel";

const Payrollr = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/payrollr/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/payrollr/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/payrollr/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/payrollr/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/payrollr/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/payrollr/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform: goToSlide === index ? "scale(1.5)" : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Paie" />
            </div>

            <HeaderText>
              La Révolution de la Gestion de Paie au Cameroun
            </HeaderText>
          </div>
        </div>
        <div className="grid md:grid-cols-12  mt-12">
          <div className="md:col-span-4"></div>
          <div className="md:col-span-8">
            <p className="font-bold mb-6">Contexte</p>
            <p>
              Les entreprises camerounaises, confrontées à la complexité du
              droit du travail et des conventions collectives locales, peinaient
              à trouver des outils de gestion de paie adaptés à leurs besoins
              spécifiques. Les solutions internationales comme Sage, Odoo, SAP,
              ou même Excel, malgré leur efficacité reconnue, ne répondaient pas
              pleinement aux exigences réglementaires camerounaises. Cette
              lacune mettait en péril la conformité des entreprises, augmentait
              les risques financiers lors des contrôles et rendait la gestion de
              la paie coûteuse, surtout pour les PME incapables de s'offrir les
              services de cabinets de comptabilité renommés.
            </p>
          </div>
        </div>
      </SectionContainer>

      <SectionContainer>
        <img
          src="/assets/images/payrollr.svg"
          alt="antene"
          className="max-w-full w-full h-auto"
        />
      </SectionContainer>

      <SectionContainer>
        <GridContainer
          title="Solution Innovante : 
          Payrollr"
          secondChildren={<ApproachDetails />}
        />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4md:hidden">
          <img
            src="/assets/images/payrollr/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/payrollr/img2.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/payrollr/img3.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Résultats"
          secondChildren={
            <p>
              Payrollr a transformé la gestion de la paie au Cameroun en offrant
              une solution qui répond spécifiquement aux défis locaux. Sa
              capacité à intégrer les règles du droit du travail et les
              conventions collectives camerounaises en fait un outil
              indispensable pour les entreprises de toutes tailles. L'innovation
              de Payrollr réside dans sa conception centrée sur l'utilisateur,
              sa facilité d'accès, et surtout, son adéquation parfaite avec le
              cadre réglementaire camerounais, démontrant ainsi un impact
              significatif sur la conformité, l'efficience et l'expérience
              utilisateur globale.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={
            <p>
              Payrollr représente une avancée majeure dans la simplification et
              l'optimisation de la gestion de paie au Cameroun, répondant aux
              besoins de divers acteurs économiques et sociaux. En alliant
              technologie, connaissance locale et accessibilité, Payrollr
              établit un nouveau standard dans la gestion des ressources
              humaines, propulsant les entreprises camerounaises vers une
              gestion de paie conforme, efficace et transparente.
            </p>
          }
        />
      </SectionContainer>

      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(Payrollr);
